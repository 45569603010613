import axios from 'axios'
import { getToken } from './auth'

const api = axios.create({
    baseURL: 'https://api.podio.digital/',
})

api.interceptors.request.use(async (config) => {
    const token = getToken()
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

const noInterceptApi = axios.create({
    baseURL: 'https://api.podio.digital/',
})

export { api, noInterceptApi }
