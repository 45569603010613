import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import PhotoLobo from '../../assets/lobopic.jpg'
import PhotoOrtsac from '../../assets/ortsacpic.jpg'
import PhotoGilberti from '../../assets/gilbertipic.jpeg'
import PhotoPraca from '../../assets/pracapic.jpeg'
import PhotoUeto from '../../assets/uetophoto.jpg'
import InstagramSvg from '../../assets/instagram.svg'
import YoutubeSvg from '../../assets/youtube.svg'
import { ReactComponent as IconInfo } from '../../assets/iconinfo.svg'
import { ReactComponent as IconPhy } from '../../assets/iconphy.svg'
import { ReactComponent as IconMath } from '../../assets/iconmath.svg'
import BenefitsGrid from '../../components/BenefitsGrid'
import MentoriaOptions from '../../components/MentoriaOptions'

const Home = ({ history }) => {
    return (
        <div>
            <section id="main">
                <div className="hero min-h-[93vh]">
                    <div className="hero py-10">
                        <div className="hero-content flex-col lg:flex-row">
                            <div className="w-full lg:w-[80%] mt-4 lg:mt-0 lg:pl-10">
                                <h1 className="text-5xl sm:text-5xl lg:text-6xl font-bold">
                                    ELEVE SEU NÍVEL COM{' '}
                                    <span className="text-secondary">
                                        MENTORIAS PARTICULARES
                                    </span>
                                </h1>
                                <p className="py-6 text-lg sm:text-xl">
                                    Seja treinado em aulas individuais para as
                                    olimpíadas de{' '}
                                    <span className="text-primary font-bold">
                                        informática
                                    </span>
                                    ,{' '}
                                    <span className="text-primary font-bold">
                                        matemática
                                    </span>{' '}
                                    ou{' '}
                                    <span className="text-primary font-bold">
                                        física
                                    </span>{' '}
                                    pela equipe com os melhores resultados na
                                    OBI, e com experiência em diversas outras
                                    olímpiadas, como OBM/OBMEP, e OBF/TBF. Mande
                                    uma mensagem para mais informações!
                                </p>
                                <div className="flex space-y-3 md:space-y-0 md:space-x-4 flex-col md:flex-row">
                                    <a
                                        className="btn btn-outline btn-success text-xl"
                                        href="http://wa.me/5585999653229"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            width="30"
                                            height="30"
                                            viewBox="0 0 48 48"
                                        >
                                            <path
                                                fill="#fff"
                                                d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                                            ></path>
                                            <path
                                                fill="#fff"
                                                d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                                            ></path>
                                            <path
                                                fill="#cfd8dc"
                                                d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                                            ></path>
                                            <path
                                                fill="#40c351"
                                                d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                                            ></path>
                                            <path
                                                fill="#fff"
                                                fillRule="evenodd"
                                                d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                        AULA EXPERIMENTAL
                                    </a>
                                    <a
                                        className="btn btn-outline btn-error text-xl px-[8px]"
                                        href="https://chat.whatsapp.com/L9zXgsuFHQkKFkIEVf7ecv"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        📣 GRUPO DE AVISOS
                                    </a>
                                </div>
                            </div>
                            <div className="w-full">
                        <div className="relative w-full overflow-hidden pt-[56.25%]">
                            <iframe
                                className="absolute top-0 left-0 right-0 w-full h-full border-none"
                                src="https://www.youtube.com/embed/QkztAfXy4xw"
                                width="560"
                                title="Live Intensivão podio.digital"
                                height="315"
                                allowFullScreen="allowFullScreen"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            ></iframe>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="mentoria">
                <div class>
                    <h2 className="text-center text-accent text-5xl font-extrabold pb-10">
                        SEJA CAMPEÃO COMEÇANDO HOJE
                    </h2>
                    <BenefitsGrid />
                    <MentoriaOptions />
                </div>
            </section>
            <section id="materiais">
                <h2 className="text-center text-5xl font-extrabold pb-10 text-accent">
                    Conteúdos Grátis
                </h2>
                <div className="flex flex-col md:flex-row items-center md:items-start justify-center pb-6">
                    <div className="w-[70%] md:w-1/3 flex justify-center md:justify-end pb-6 md:pb-0 md:pr-6">
                        <div className="card w-96 bg-base-100 shadow-xl">
                            <figure className="px-10 pt-10">
                                <IconInfo stroke="oklch(var(--a))" />
                            </figure>
                            <div className="card-body items-center text-center">
                                <h2 className="card-title text-accent text-3xl">
                                    INFORMÁTICA
                                </h2>
                                <div className="badge badge-secondary text-lg font-bold">
                                    INTENSIVÃO DISPONÍVEL
                                </div>
                                <p>
                                    Treine com a equipe com os melhores
                                    resultados em 2023 na modalidade programação
                                    da OBI.
                                </p>
                                <div className="card-actions">
                                    <a
                                        href="/informatica"
                                        className="btn btn-primary"
                                    >
                                        CONTEÚDOS
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[70%] md:w-1/3 flex justify-center md:justify-center pb-6 md:pb-0 md:pr-6">
                        <div className="card w-96 bg-base-100 shadow-xl">
                            <figure className="px-10 pt-10">
                                <IconMath fill="oklch(var(--a))" />
                            </figure>
                            <div className="card-body items-center text-center">
                                <h2 className="card-title text-accent text-3xl">
                                    MATEMÁTICA
                                </h2>
                                <div className="badge badge-secondary text-lg font-bold">
                                    LIVE GRAVADA
                                </div>
                                <p>
                                    Treine com multimedalhistas na OBM/OBMEP,
                                    com experiência no ensino da matemática.
                                </p>
                                <div className="card-actions">
                                    <a
                                        href="/matematica"
                                        className="btn btn-primary"
                                    >
                                        CONTEÚDOS
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[70%] md:w-1/3 flex justify-center md:justify-start">
                        <div className="card w-96 bg-base-100 shadow-xl">
                            <figure className="px-10 pt-10">
                                <IconPhy fill="oklch(var(--a))" />
                            </figure>
                            <div className="card-body items-center text-center">
                                <h2 className="card-title text-accent text-3xl">
                                    FÍSICA
                                </h2>
                                <div className="badge badge-secondary text-lg font-bold">
                                    LIVE DOMINGO 16/6
                                </div>
                                <p>
                                    Treine com membros das equipes das
                                    olimpíadas internacionais de física com
                                    experiência ensinando para alunos de E.M.!
                                </p>
                                <div className="card-actions">
                                    <a
                                        href="/fisica"
                                        className="btn btn-primary"
                                    >
                                        CONTEÚDOS
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="sobre-nos">
                <h2 className="text-center text-5xl font-extrabold pb-10 text-accent">
                    Sobre Nós
                </h2>
                <div className="flex flex-col md:flex-row items-center md:items-start justify-center pb-6">
                    <div className="w-full md:w-1/3 flex justify-center md:justify-end pb-6 md:pb-0 md:pr-6">
                        <div className="card max-w-xs bg-base-100 shadow-xl">
                            <figure>
                                <img
                                    src={PhotoLobo}
                                    className="w-full rounded-lg shadow-2xl"
                                    alt="Foto do Arthur Lobo"
                                />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">
                                    Arthur Lobo
                                    <div className="badge badge-secondary whitespace-nowrap">
                                        CO-FUNDADOR
                                    </div>
                                </h2>
                                <p className="text-center text-lg">
                                    4 vezes medalhista de ouro da OBI e primeiro
                                    lugar na América Latina. Professor desde
                                    2021 com seus alunos tendo conquistado mais
                                    de 19 medalhas na OBI e se classificado pra
                                    olimpíadas internacionais.
                                </p>
                                <div className="card-actions justify-end">
                                    <div className="badge badge-outline">
                                        Professor
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 flex justify-center md:justify-center pb-6 md:pb-0 md:pr-6">
                        <div className="card max-w-xs bg-base-100 shadow-xl">
                            <figure>
                                <img
                                    src={PhotoOrtsac}
                                    className="w-full rounded-lg shadow-2xl"
                                    alt="Foto do João Pedro Castro"
                                />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">
                                    João Pedro Castro
                                    <div className="badge badge-secondary whitespace-nowrap">
                                        CO-FUNDADOR
                                    </div>
                                </h2>
                                <p className="text-center text-lg">
                                    Ouro na OBI PJ, Bronze na OBI P2.
                                    <br />
                                    Professor de programação por 1 ano.
                                </p>
                                <div className="card-actions justify-end">
                                    <div className="badge badge-outline">
                                        Professor
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 flex justify-center md:justify-start">
                        <div className="card max-w-xs bg-base-100 shadow-xl">
                            <figure>
                                <img
                                    src={PhotoGilberti}
                                    className="w-full rounded-lg shadow-2xl"
                                    alt="Foto do Gilberti"
                                />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">João Gilberti</h2>
                                <p className="text-center text-lg">
                                    Multimedalhista na OBM/OBMEP com experiência
                                    no ensino da matemática.
                                </p>
                                <div className="card-actions justify-end">
                                    <div className="badge badge-outline">
                                        Professor
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row items-center md:items-start justify-center pb-6">
                    <div className="w-full md:w-1/3 flex justify-center md:justify-center pb-6 md:pb-0 md:pr-6">
                        <div className="card max-w-xs bg-base-100 shadow-xl">
                            <figure>
                                <img
                                    src={PhotoPraca}
                                    className="w-full rounded-lg shadow-2xl"
                                    alt="Foto do Lucas Praça"
                                />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Lucas Praça</h2>
                                <p className="text-center text-lg">
                                    2x Prata OBF + Prata TBF
                                    <br />
                                    Time EuPhO Brasil 2024
                                    <br />
                                    Time OLAA Brasil 2024
                                    <br />
                                    Experiência ensinando física para alunos de
                                    E.M.
                                </p>
                                <div className="card-actions justify-end">
                                    <div className="badge badge-outline">
                                        Professor
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contato">
                <div className="hero py-4">
                    <div className="hero-content text-center">
                        <div>
                            <h1 className="text-5xl font-bold text-accent">
                                Contato
                            </h1>
                            <p className="py-6 text-xl max-w-md">
                                Qualquer dúvida ou sugestão entre em contato e
                                responderemos o quanto antes :D
                            </p>
                            <a
                                href="mailto:contato@podio.digital"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline btn-secondary text-xl font-bold"
                            >
                                <svg
                                    width="30px"
                                    height="30px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.99946 7.51126C2.09768 8.08885 1.5 9.09963 1.5 10.25V16.25C1.5 19.1495 3.85051 21.5 6.75 21.5H15.75C16.9004 21.5 17.9112 20.9023 18.4887 20.0005L6.75 20C4.67893 20 3 18.3211 3 16.25L2.99946 7.51126ZM18.75 4H7.25C5.51697 4 4.10075 5.35645 4.00514 7.06558L4 7.25V15.75C4 17.483 5.35645 18.8992 7.06558 18.9949L7.25 19H18.75C20.483 19 21.8992 17.6435 21.9949 15.9344L22 15.75V7.25C22 5.51697 20.6435 4.10075 18.9344 4.00514L18.75 4ZM5.5 8.899L12.6507 12.6637C12.8381 12.7623 13.0569 12.7764 13.2532 12.706L13.3493 12.6637L20.5 8.9V15.75C20.5 16.6682 19.7929 17.4212 18.8935 17.4942L18.75 17.5H7.25C6.33183 17.5 5.57881 16.7929 5.5058 15.8935L5.5 15.75V8.899ZM7.25 5.5H18.75C19.6682 5.5 20.4212 6.20711 20.4942 7.10647L20.498 7.206L13 11.1525L5.50057 7.20483C5.52453 6.25921 6.2986 5.5 7.25 5.5Z"
                                        fill="oklch(var(--s))"
                                    />
                                </svg>
                                Enviar email
                            </a>
                            <a
                                href="https://instagram.com/opodio.digital"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline btn-secondary mx-3 text-xl font-bold"
                            >
                                <img src={InstagramSvg} alt="instagram logo" />
                                Instagram
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCQFfISiskvJ4oRgCkHyCHTA"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline btn-secondary text-xl font-bold"
                            >
                                <img src={YoutubeSvg} alt="instagram logo" />
                                YouTube
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default withRouter(Home)
