import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import SignUp from './pages/SignUp'
import SignIn from './pages/SignIn'
import Activate from './pages/Activate'
import RequestMudarSenha from './pages/RequestMudarSenha'
import MudarSenha from './pages/MudarSenha'
import Home from './pages/Home'
import { isAuthenticated } from './services/auth'
import Navbar from './components/Navbar'
import GratisInfo from './pages/GratisInfo'
import InfoIntensivao from './pages/GratisInfo/IntensivaoInfo'
import GratisMat from './pages/GratisMat'
import GratisPhy from './pages/GratisPhy'
import AppPage from './pages/AppPage'
import TeacherPage from './pages/TeacherPage'
export function PrivateRoute({ children }) {
    return isAuthenticated() ? children : <Redirect to="/login" />
}
const Routes = () => (
    <BrowserRouter>
        <Navbar />
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={SignIn} />
            <Route path="/ativar/:token" component={Activate} />
            <Route path="/mudar-senha/:token" component={MudarSenha} />
            <Route path="/cadastro" component={SignUp} />
            <Route path="/request-mudar-senha" component={RequestMudarSenha} />
            <Route exact path="/informatica" component={GratisInfo} />
            <Route path="/informatica/intensivao" component={InfoIntensivao} />
            <Route path="/matematica" component={GratisMat} />
            <Route path="/fisica" component={GratisPhy} />
            <Route path="/app">
                <PrivateRoute>
                    <AppPage />
                </PrivateRoute>
            </Route>
            <Route path="/onlyteachers">
                <PrivateRoute>
                    <TeacherPage />
                </PrivateRoute>
            </Route>
            <Route path="*" component={() => <h1>Page not found</h1>} />{' '}
        </Switch>
    </BrowserRouter>
)

export default Routes
