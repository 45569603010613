export const TOKEN_KEY = '@podio-Token'

export const getToken = () => {
    const itemStr = localStorage.getItem(TOKEN_KEY)
    if (!itemStr) return null
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(TOKEN_KEY)
        return null
    }
    return item.value
}

export const isAuthenticated = () => {
    return getToken() !== null
}
export const login = (token) => {
    const now = new Date()

    const item = {
        value: token,
        expiry: now.getTime() + 60 * 60 * 24 * 1000,
    }
    localStorage.setItem(TOKEN_KEY, JSON.stringify(item))
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY)
}
